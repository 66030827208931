<template>
  <div id="designation-and-trademark">
    <b-container class="base-container-x" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <!-- <div v-if="loading === false" id="banner" class="banner-inner">
      </div> -->
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4">
        <h2 class="text-center font-weight-bold"><u>{{ $t('WYA Designation & Trademark') }}</u></h2>
        <p class="mt-5">{{ $t('Our name, World Yoga Alliance® is a registered logo and trademark. Both of these logos depicted below are included for our registered and certified yoga schools and teachers. Our worldwide members can use the first depicted trademark accordingly to their registration or certification type and validity. The second logo; The World Yoga Alliance trademark is federally registered with the Republic of India and Kingdom of Thailand Patent and Trademark Office and in other countries and jurisdictions and can only be used by WYA official office as their stamp.') }}</p>
        <b-row class="mt-5">
          <b-col cols="12" sm="6" lg="6" class="text-center">
            <b-img class="w-25" :src="require('../../assets/images/logo/logo.png')" />
            <p class="mt-3 d-block d-sm-none"><i>{{ $t('Official Logo for Every Member') }}</i></p>
          </b-col>
          <b-col cols="12" sm="6" lg="6" class="d-block d-sm-flex mt-3 mt-sm-0 align-content-center">
            <div class="d-flex justify-content-center align-items-center">
              <b-img class="w-25" :src="require('../../assets/images/logo/wya_stamp_new.png')" />
              <b-img class="w-25" :src="require('../../assets/images/logo/logo_stamp.png')" />
            </div>
            <div class="d-flex justify-content-center align-items-between">
               <p class="mt-3 d-block d-sm-none"><i>{{ $t('Our Stamp - Only for WYA Official use') }}</i></p>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4 d-none d-sm-flex">
          <b-col cols="6" >
            <p class="text-center"><i>{{ $t('Official Logo for Every Member') }}</i></p>
          </b-col>
          <b-col cols="6">
            <p class="text-center"><i>{{ $t('Our Stamp - Only for WYA Official use') }}</i></p>
          </b-col>
        </b-row>
        <div class="mt-5">
          <h4 class="text-center">{{ $t('General Guidelines for WYA Mark Users:') }}</h4>
          <p class="text-center"><i>{{ $t('Last Updated: April 29th, 2021') }}</i></p>
          <p>{{ $t('The following guidelines are applicable to our licensees who use the World Yoga Alliance Name Trademarks or any other WYA copyright logos in their website, social media channels, in marketing or advertising materials, or in any other manner or medium. Below are the Trademark Guidelines of what you should do (“DO”) and what you should not do (“DON’T”) If you are a registered or certified member of World Yoga Alliance Organization. ') }}</p>
          <p class="text-uppercase">{{ $t('Do') }}</p>
          <ol>
            <li>{{ $t('Valid registration with World Yoga Alliance as registration affords you the permission to use our Trademark(s), Logo(s) or Designation(s) for which you are validly registered.') }}</li>
            <li>{{ $t('We will provide our Trademark Logo or Designations Files for your use via email according to your membership type. Or you can download the files from our') }} <router-link :to="{ name: 'exclusiveCenter' }"><u>{{ $t('EXCLUSIVE CENTER.') }}</u></router-link></li>
            <li>{{ $t('The trademarks or logos should be used in the exact form that we have provided you via email or download from our Website.') }}</li>
            <li>{{ $t('In your Website or blog, use the \'WORLD YOGA ALLIANCE\' name in capital letters and link to') }} <a href="https://www.wyayoga.org/" target="_blank">www.wyayoga.org</a> {{ $t('OR') }} <u><router-link :to="{ name: 'userProfile'}">{{ $t('Your WYA profile') }}</router-link></u></li>
          </ol>
          <p class="text-uppercase">{{ $t('Don’t') }}</p>
          <ol>
            <li>{{ $t('The logo/trademark/designation is not to be abbreviated, changed or combined with any other words, symbols, designs or letters.') }}</li>
            <li>{{ $t('You must not give permission or allow any other third-parties including non WYA Member(s) to use our Name, logo or trademarks without written permission from World Yoga Alliance office.') }}</li>
            <li>{{ $t('World Yoga Alliance does not give permission or rights to our registered and certified members to use any non-WYA trademark or logos on our platform. We are not involved in any trademark dispute arising from non-WYA marks.') }}</li>
            <li>{{ $t('Our Certified Yoga School Partners may not be used by any non-WYA marks, organizations or individuals. You can read more about "The License Policy" in our') }} <router-link :to="{ name: 'termsAndConditions' }"><u>{{ $t('Terms and Condition Page.') }}</u></router-link></li>
          </ol>
        </div>
        <div class="mt-5">
          <h4 class="text-center mb-3">{{ $t('Collectively “World Yoga Alliance Trademarks: ') }}</h4>
          <p>{{ $t('Registered Schools (RSY) and Registered Teachers (RTY) have standards for hours (200-hours or 250-hours as level 1 /300-hours or 400-hours as level 2 /500-hours or 600-hours as level 3 / 1,000-hours as level 4). For those who registrar as Special Category Schools or Teachers (including Arial/Kids/prenatal/Yoga Therapy/Ayurveda/Yin Yoga/Meditation & Spiritual) will get a special logo (depicted below). Our Certified Yoga school (CYS) is a part of our core education system and we support them as our yoga family.') }} <router-link :to="{ name: 'CYS' }"><u>{{ $t('Read the Certified Yoga School Standards Page') }}</u></router-link> {{ $t('to find out more about all of the exclusive benefits. Our Certified Yoga Schools provide training diploma to the CYT upon completion. Once the diploma is received, the CYT can apply for the Exchange Yoga Experience Program (EYEP). Online Yoga Course Provider (OYCP) trademark is for any school who offers online yoga training or courses in any specific subject. Genuine Yoga Studio (GYS) trademark is for any yoga lovers who do not have any courses but have the space to provide yoga classes. Check all the') }} <router-link :to="{ name: 'standards' }"><u>{{ $t('standards') }}</u></router-link> {{ $t('pages for more details.') }}</p>
          <b-table-simple caption-top responsive bordered class="table-default d-none d-sm-block mt-3">
            <b-thead>
              <b-tr>
                <b-th colspan="3" class="w-50 text-uppercase text-center" variant="light" >{{ $t('School\'s') }}</b-th>
                <b-th colspan="3" class="w-50 text-uppercase text-center" variant="light" >{{ $t('Teacher\'s') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>1</b-td>
                <b-td class="w-25 vertical-align-middle">{{ $t('Registered School of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/RSY.png'" />
                </b-td>
                <b-td>1</b-td>
                <b-td class="w-25">{{ $t('Registered Teacher of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/RTY.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td>2</b-td>
                <b-td class="w-25">{{ $t('Experienced Registered Teacher of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/ERTY.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>2</b-td>
                <b-td class="w-25">{{ $t('Aerial Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYS.png'" />
                </b-td>
                <b-td>3</b-td>
                <b-td class="w-25">{{ $t('Aerial Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>3</b-td>
                <b-td class="w-25">{{ $t('Kids Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/KYS.png'" />
                </b-td>
                <b-td>4</b-td>
                <b-td class="w-25">{{ $t('kids Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/KYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>4</b-td>
                <b-td class="w-25">{{ $t('Prenatal Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/PYS.png'" />
                </b-td>
                <b-td>5</b-td>
                <b-td class="w-25">{{ $t('Prenatal Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/PYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>5</b-td>
                <b-td class="w-25">{{ $t('Yoga Therapy School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YTS.png'" />
                </b-td>
                <b-td>6</b-td>
                <b-td class="w-25">{{ $t('Yoga Therapy Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YTT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>6</b-td>
                <b-td class="w-25">{{ $t('Ayurveda School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYS.png'" />
                </b-td>
                <b-td>7</b-td>
                <b-td class="w-25">{{ $t('Ayurveda Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>7</b-td>
                <b-td class="w-25">{{ $t('Yin Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YYS.png'" />
                </b-td>
                <b-td>8</b-td>
                <b-td class="w-25">{{ $t('Yin Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>8</b-td>
                <b-td class="w-25">{{ $t('Meditation & Spiritual School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/MSS.png'" />
                </b-td>
                <b-td>9</b-td>
                <b-td class="w-25">{{ $t('Meditation & Spiritual Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/MST.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>9</b-td>
                <b-td class="w-25">{{ $t('Certified Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/CYS.png'" />
                </b-td>
                <b-td>10</b-td>
                <b-td class="w-25">{{ $t('Certified Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/CYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>10</b-td>
                <b-td class="w-25">{{ $t('Exchange Yoga Experience Program') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/EYEP.png'" />
                </b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
              </b-tr>
              <b-tr>
                <b-td>11</b-td>
                <b-td class="w-25">{{ $t('Online Yoga Course Provider') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/OYCP.png'" />
                </b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
              </b-tr>
              <b-tr>
                <b-td>12</b-td>
                <b-td class="w-25">{{ $t('Genuine Yoga Studio') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/GYS.png'" />
                </b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top responsive bordered class="table-default d-block d-sm-none mt-3">
            <b-thead>
              <b-tr>
                <b-th colspan="3" class="w-50 text-uppercase text-center" variant="light" >{{ $t('School\'s') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>1</b-td>
                <b-td class="w-25 vertical-align-middle">{{ $t('Registered School of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/RSY.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>2</b-td>
                <b-td class="w-25">{{ $t('Aerial Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>3</b-td>
                <b-td class="w-25">{{ $t('Kids Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/KYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>4</b-td>
                <b-td class="w-25">{{ $t('Prenatal Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/PYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>5</b-td>
                <b-td class="w-25">{{ $t('Yoga Therapy School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YTS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>6</b-td>
                <b-td class="w-25">{{ $t('Ayurveda School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>7</b-td>
                <b-td class="w-25">{{ $t('Yin Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>8</b-td>
                <b-td class="w-25">{{ $t('Meditation & Spiritual School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/MSS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>9</b-td>
                <b-td class="w-25">{{ $t('Certified Yoga School') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/CYS.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>10</b-td>
                <b-td class="w-25">{{ $t('Exchange Yoga Experience Program') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/EYEP.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>11</b-td>
                <b-td class="w-25">{{ $t('Online Yoga Course Provider') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/OYCP.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>12</b-td>
                <b-td class="w-25">{{ $t('Genuine Yoga Studio') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/GYS.png'" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top responsive bordered class="table-default d-block d-sm-none mt-3">
            <b-thead>
              <b-tr>
                <b-th colspan="3" class="w-50 text-uppercase text-center" variant="light" >{{ $t('Teacher\'s') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>1</b-td>
                <b-td class="w-25">{{ $t('Registered Teacher of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/RTY.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>2</b-td>
                <b-td class="w-25">{{ $t('Experienced Registered Teacher of Yoga') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/ERTY.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>3</b-td>
                <b-td class="w-25">{{ $t('Aerial Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>4</b-td>
                <b-td class="w-25">{{ $t('kids Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/KYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>5</b-td>
                <b-td class="w-25">{{ $t('Prenatal Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/PYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>6</b-td>
                <b-td class="w-25">{{ $t('Yoga Therapy Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YTT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>7</b-td>
                <b-td class="w-25">{{ $t('Ayurveda Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>8</b-td>
                <b-td class="w-25">{{ $t('Yin Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YYT.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>9</b-td>
                <b-td class="w-25">{{ $t('Meditation & Spiritual Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/MST.png'" />
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>10</b-td>
                <b-td class="w-25">{{ $t('Certified Yoga Teacher') }}</b-td>
                <b-td class="text-center">
                  <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/CYT.png'" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <p class="font-weight-bold text-uppercase">{{ $t('Note') }}:</p>
          <p>{{ $t('If you are not a member or affiliate of World Yoga Alliance / WYA Community we do not allow you to use our any Trademarks, Designations or WYA logos. All are the intellectual property of World Yoga Alliance.') }}</p>
          <p>{{ $t('We thank you for your cooperation. If evidence found of any unauthorized use of WYA copyright infringement, please notify to World Yoga Alliance at') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a> {{ $t('Do not forget to attach a copy or screenshot of an unauthorized use with the email.') }}</p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'WYADeSignationTrademark',
  title: ' | WYA Designation & Trademark',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #designation-and-trademark {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
